@import 'node_modules/bourbon/core/bourbon';
@import 'lib/fontawesome/fontawesome.scss';
@import 'lib/fontawesome/fa-solid.scss';
@import 'lib/fontawesome/fa-brands.scss';

// Typography
$main-font: 'Work Sans', sans-serif;

// Palette
$green: #00ca8e;
$green-lighter: #d9f7ee;
$green-light: #66dfbb;
$green-dark: #08a977;

$yellow: #ffdb47;
$yellow-lighter: #fff7d7;
$yellow-light: #ffe786;
$yellow-dark: #e7c439;

$blue: #24c4c8;
$blue-lighter: #dcf5f6;
$blue-light: #7edbde;
$blue-dark: #21a7ab;

$magenta: #b15dcb;
$magenta-lighter: #eadcf3;
$magenta-light: #cb9ade;
$magenta-dark: #8f38a7;

$red: #ff246d;
$red-lighter: #ffdae7;
$red-light: #ff77a5;
$red-dark: #db1e5e;
$red-checkin: #dc9be1;

$purple: #6362d6;
$purple-lighter: #e5e5f8;
$purple-light: #a1a1e6;
$purple-dark: #5352b3;

$orange: #fea424;
$orange-lighter: #ffedd3;
$orange-light: #fecd87;
$orange-dark: #e59118;

$black: #16171a;

$gray-1: #43474f;
$gray-2: #86909e;
$gray-3: #b1b7c0;
$gray-4: #e8eaeb;
$gray-5: #f3f2f1;
$gray-6: #faf9f9;

$white: #ffffff;

[v-cloak] {
  display: none
}

@mixin relative {
  @include position(relative, null null null);
}
@mixin translate($x, $y) {
  @include prefixer(transform, translate($x, $y), ('webkit', 'moz'));
}
@mixin translateX($x) {
  @include prefixer(transform, translateX($x), ('webkit', 'moz'));
}
@mixin translateY($y) {
  @include prefixer(transform, translateY($y), ('webkit', 'moz'));
}
@mixin rotate($x) {
  @include prefixer(rotate, rotate($x), ('webkit', 'moz'));
}
@mixin transition($time) {
  -webkit-transition: $time;
  transition: $time;
}
@mixin desktop-1350 {
  @media screen and (max-width: 1350px) {
    @content;
  }
}
@mixin tablet-1100 {
  @media screen and (max-width: 1100px) {
    @content;
  }
}
@mixin tablet-900 {
  @media screen and (max-width: 900px) {
    @content;
  }
}
@mixin tablet-768 {
  @media screen and (max-width: 800px) {
    @content;
  }
}
@mixin phone-550 {
  @media screen and (max-width: 550px) {
    @content;
  }
}
.white-text {
  color: $white;
}
.green-text {
  color: $green-lighter;
}
.gray-text-2 {
  color: $gray-2;
}
.green-lighter-text {
  color: $green-lighter;
}
.purple-lighter-text {
  color: $purple-lighter;
}
.title {
  font-size: 28px;
  font-weight: bold;
  line-height: 39px;
  @include size(100%, auto);
  max-width: 460px;
  text-align: center;
}
.title-bot-border {
  @include position(relative, null null null null);
  &:after {
    content: '';
    @include size(90px, 3px);
    @include position(absolute, null null -8px 50%);
    @include translate(-50%, -50%);
  }
}
.home-title-bot-border {
  @include position(relative, null null null null);
  &:after {
    content: '';
    @include size(90px, 4px);
    @include position(absolute, null null -8px 50%);
    @include translate(-50%, -50%);
  }
}
.purple-bot-border:after {
  background-color: $purple;
}
.green-bot-border:after {
  background-color: $green-light;
}
.main-green-bot-border:after {
  background-color: $green;
}
.main-orange-bot-border:after {
  background-color: $orange;
}
.gray-bot-border:after {
  background-color: $gray-2;
}
.yellow-bot-border:after {
  background-color: $yellow;
}
figure {
  @include margin(0);
}
@keyframes shake {
  0%,
  25%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translate(4px);
  }
}
.container {
  display: grid;
  min-height: 100vh;
}
*.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
